<template>
  <div>
    <div class="custom-shape-divider-top-two">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1741,116 c291,117,0,27,0,6 V120 H1215 V0 C1100,27,1086,145,441,116Z" class="shape-fill">
        </path>
      </svg>
    </div>
    <div class="custom-shape-divider-top-one">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0 V6 c-100,-21,291,111,1241,110,745,3,1259-88,459-110 V0Z" class="shape-fill"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackgroundDraw',
}
</script>
