<template>
  <div class="row m-0 p-0">
    <div class="col-12 align-items-center text-center p-0 m-0">
        <div class="d-flex align-items-center justify-content-center pt-2 pb-0 mb-0">
          <div class="d-flex justify-content-center align-items-center mt-3 mb-3 mr-5">
            <i class="el-type mdi mdi-shield-outline position-absolute text-size-110 z-0 opacity-20 mb-3"></i>
            <h2><i class="fa fa-user welcome-icon z-1"></i></h2>
          </div>
          <div class="ml-5">
            <h2>
              {{ fullName }}
            </h2>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
 .welcome-icon {
   border: solid 5px;
   border-radius: 10px;
   padding: 3px;
   margin-bottom: 10px;
 }

 .opacity-20 {
   opacity: 20%;
 }
</style>

<script>

export default {
  name: 'WelcomeDashboard',
  data: () => ({
    selection: 1
  }),
  computed: {
    user: {
      get: function () {
        return this.$store.state.dashboardStore.user
      },
      set: function (value) {
        this.$store.commit('dashboardStore/updateUser', value)
      }
    },
    fullName: function () {
      return this.user.firstName + ' ' + this.user.lastName
    }
  },
  created () {},
  methods: {}
}
</script>
