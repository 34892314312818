<template>
  <v-row justify="center">
    <v-dialog
      v-model="previewReleaselogDialogState"
      width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.release_logs') }}</span>
        </v-card-title>
        <v-card outlined tile>
          <v-card-title>
            <div v-if="releaselog">
              <h5>{{ resolveTitle(releaselog) }}</h5>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <small>
              <div v-if="releaselog">{{ releaselog.publishDate | dateFormat }}</div>
            </small>
          </v-card-subtitle>
          <div v-if="releaselog">
            <v-card-text class="text-justify" v-html="resolveContent(releaselog)"></v-card-text>
          </div>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button type="button" @click="$emit('closePreviewReleaseDialog')" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content" >{{ $t('message.close') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: 'PreviewReleaselogDialog',
  props: {
    releaselog: Object,
    previewReleaselogDialogState: Boolean
  },
  computed: {
    locale () {
      return localStorage.getItem('locale') || 'fr'
    }
  },
  methods: {
    resolveTitle (releaselog) {
      let locale = this.locale

      if (!releaselog.translations[this.locale] || !releaselog.translations[this.locale].title) {
        locale = 'fr'
      }

      return releaselog.translations[locale].title
    },
    resolveContent (releaselog) {
      let locale = this.locale

      if (!releaselog.translations[this.locale] || !releaselog.translations[this.locale].content) {
        locale = 'fr'
      }

      return releaselog.translations[locale].content
    }
  }
}
</script>
