<template>
  <v-card class="w-100 rounded-xl card-storage" height="100%">
    <v-card-title align="center" class="text-primary font-weight-bold justify-center">{{ $t('message.storage') }}</v-card-title>

    <v-card-text class="justify-center text-center pt-0 pb-0 mb-0">
      <canvas ref="storageChart" style="margin-top:-70px; margin-bottom: -50px;"></canvas>
      <div class="font-weight-bold position-relative" :class="[
                                                                windowWidth > 1245 ? 'text-size-32' : 'text-size-24',
                                                                windowWidth > 1100 ? 'top--50' : 'top--30']" >
        {{ $store.getters["storageStore/calculatePercentage"] }}
      </div>
      <div class="font-weight-bold position-relative" :class="[ windowWidth > 1245 ? 'top--40' : '',
                                                                windowWidth < 1245 && windowWidth >= 1000 ? 'top--30' : '',
                                                                windowWidth < 1000 ? 'top--20' : ''
                                                              ]">

        {{ $t('message.usage', { used: formatedCurrentUsage(), max: formatedChartMaxUsage() }) }}
      </div>
    </v-card-text>

    <v-card-text class="pt-0 pb-0 mb-0 mt-0">
      <div>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="legend">
                <svg width="25" height="25">
                  <rect width="25" height="25" class="fill-document" rx="5"></rect>
                </svg>
                <span class="legend-description">{{ $t('message.documents') }}</span><span class="align-middle text-size-16"> ({{ (formatedCurrentUsage() - formatedTrashUsage()).toFixed(2) }} {{getLegendLabelOfSpace()}})</span>
              </div>
            </v-list-item-title>
            <v-list-item-title>
              <div class="legend">
                <svg width="25" height="25">
                  <rect width="25" height="25" class="fill-corbeille" style="fill:#36A2EB;" rx="5"></rect>
                </svg>
                <span class="legend-description">{{ $t('message.trash') }}</span><span class="align-middle text-size-16"> ({{formatedTrashUsage()}} {{getLegendLabelOfSpace()}})</span>
              </div>
            </v-list-item-title>
            <v-list-item-title>
              <div class="legend">
                <svg width="25" height="25">
                  <rect width="25" height="25" class="fill-free-space" rx="5"></rect>
                </svg>
                <span class="legend-description">{{ $t('message.storage_status') }}</span><span class="align-middle text-size-16"> ({{ (formatedMaxUsage() - formatedCurrentUsage()).toFixed(2) }} {{getLegendLabelOfSpace()}})</span>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </div>
    </v-card-text>

    <v-card-actions class="justify-center pb-5">
      <!--      <v-btn class="text-uppercase" color="primary" elevation="2" rounded>-->
      <!--        Augmenter-->
      <!--      </v-btn>-->
    </v-card-actions>
  </v-card>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'CardStorage',
  data: () => ({
    chart: null,
    windowWidth: window.innerWidth
  }),
  created() {
    this.$store.dispatch('storageStore/getPersonalStorageStatus')
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  computed: {
    currentUsage: {
      get: function () {
        return this.$store.state.storageStore.currentUsage
      },
      set: function (value) {
        this.$store.commit('storageStore/updateCurrentUsage', parseFloat(value))
      }
    },
    maxUsage: {
      get: function () {
        return this.$store.state.storageStore.maxUsage
      },
      set: function (value) {
        this.$store.commit('storageStore/updateMaxUsage', value)
      }
    },
    trashUsage: {
      get: function () {
        return this.$store.state.storageStore.trashUsage
      },
      set: function (value) {
        this.$store.commit('storageStore/updateTrashUsage', value)
      }
    },
    documentUsage: {
      get: function () {
        return this.$store.state.storageStore.documentUsage
      },
      set: function (value) {
        this.$store.commit('storageStore/updateDocumentUsage', value)
      },
    },
    loading: {
      get: function () {
        return this.$store.state.storageStore.loading
      },
      set: function (value) {
        this.$store.commit('storageStore/setLoading', value)
      },
    }
  },
  methods: {
    reserve () {
      this.loading = true
      setTimeout(() => (this.loading = false), 2000)
    },
    loadChart () {
      if (this.chart) {
        this.chart.destroy()
      }

      Chart.defaults.elements.arc.hoverBorderColor = 'white'
      Chart.defaults.datasets.doughnut.cutout = '50%'
      this.chart = new Chart(this.$refs.storageChart, {
        type: 'doughnut',
        data: {
          labels: [
            'Document',
            'Corbeille',
            'Espace libre'
          ],
          datasets: [
            {
              data: [
                { name: 'Document', value: this.formatedCurrentUsage() - this.formatedTrashUsage() },
                { name: 'Corbeille', value: this.formatedTrashUsage() },
                { name: 'Espace libre', value: this.formatedFreeSpace() }
              ],
              //borderRadius: {
              //  outerStart: 0,
              //  outerEnd: Number.MAX_VALUE,
              //  innerStart: 0,
              //  innerEnd: Number.MAX_VALUE
              //},
              backgroundColor: [
                '#25519D',
                '#3F7CDD',
                '#C4C4C4'
              ],
              hoverBackgroundColor: [
                '#1c4486',
                '#4275c7',
                '#8a8a8a'
              ]
            }
          ]
        },
        options: {
          responsive: true,
          rotation: -90,
          circumference: 180,
          parsing: {
            key: 'value'
          },
          elements: {
            arc: {
              borderWidth: 0,
              //offset: -40,
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: true
            }
          }
        },
      })
    },
    formatedCurrentUsage () {
      if (this.maxUsage <= this.$store.state.storageStore.gigaBytes) {
        return this.currentUsage === 0 ? this.currentUsage : ((this.currentUsage / this.maxUsage) * 1000)
          .toFixed(2)
      } else {
        return this.currentUsage === 0 ? this.currentUsage : (this.currentUsage / this.maxUsage)
          .toFixed(this.$store.state.storageStore.numberFormat)
      }
    },
    formatedMaxUsage() {
      if (this.maxUsage <= this.$store.state.storageStore.gigaBytes) {
        return this.maxUsage / this.$store.state.storageStore.megaBytes
      } else {
        return this.maxUsage / this.$store.state.storageStore.gigaBytes
      }
    },
    formatedChartMaxUsage() {
      return this.maxUsage / this.$store.state.storageStore.gigaBytes
    },
    formatedTrashUsage() {
      if (this.maxUsage <= this.$store.state.storageStore.gigaBytes) {
        return this.trashUsage === 0 ? this.trashUsage : ((this.trashUsage / this.maxUsage) * 1000)
          .toFixed(2)
      } else {
        return this.trashUsage === 0 ? this.trashUsage : (this.trashUsage / this.maxUsage)
          .toFixed(this.$store.state.storageStore.numberFormat)
      }
    },
    formatedFreeSpace() {
      if (this.currentUsage === 0) {
        return this.formatedMaxUsage();
      }

      let freeSpace = this.maxUsage - this.currentUsage;
      if (this.maxUsage <= this.$store.state.storageStore.gigaBytes) {
        return (freeSpace / this.$store.state.storageStore.megaBytes).toFixed(this.$store.state.storageStore.numberFormat)
      } else {
        return (freeSpace / this.$store.state.storageStore.gigaBytes).toFixed(this.$store.state.storageStore.numberFormat)
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    getLabelOfSpace() {
      return this.$store.state.storageStore.isMegaBytes ? 'Mo' : 'Go'
    },
    getLegendLabelOfSpace() {
      return this.maxUsage <= this.$store.state.storageStore.gigaBytes ? 'Mo' : 'Go'
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    loading() {
      this.loadChart()
    },
    windowWidth(newWidth, oldWidth) {}
  }
}
</script>

<style scopped>

.fill-document {
  fill: #25519D;
}

.fill-corbeille {
  fill: #36A2EB;
}

.fill-free-space {
  fill:#C4C4C4;
}

</style>
