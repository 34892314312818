<template>
  <v-card class="rounded-xl mb-5 d-block h-100 flex-basis-70" >
    <v-card-title align="center" class="card-title-table text-primary position-absolute font-weight-bold bg-white w-100 zindex-300 justify-center">
      {{ $t('message.recent_documents_deposited_by_fiducial') }}
      <span class="ml-1 mt-2 font-weight-bold text-secondary h6">{{ $t('message.last_35_days') }}</span>
    </v-card-title>
    <v-card-text class="h-300 documents-list overflow-y-scroll">
      <SimpleTable></SimpleTable>
    </v-card-text>
  </v-card>
</template>

<script>
import SimpleTable from "./SimpleTable";

export default {
  name: 'CardTable',
  data: () => ({
    selection: 1,
  }),
  methods: {},
  components: {
    SimpleTable
  }
}
</script>

<style>
  .overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .flex-basis-70 {
    flex-basis: 70%;
  }

  .zindex-300 {
    z-index: 300;
  }

  .documents-list {
    min-height: 100%;
    padding-top: 64px !important;
  }

  @media only screen and (max-width: 700px) {
    .card-title-table {
      font-size: medium;
      padding-bottom: 0px;
    }
    .documents-list {
      min-height: 100%;
      padding-top: 100px !important;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    .card-title-table {
      font-size: medium;
      padding-bottom: 0px;
    }
    .documents-list {
      min-height: 100%;
      padding-top: 100px !important;
    }
  }

  @media only screen and (min-width: 280px) and (max-width: 600px) {
    .card-title-table {
      font-size: medium;
      padding-bottom: 0px;
    }
    .documents-list {
      min-height: 100%;
      padding-top: 120px !important;
    }
  }
</style>
