<template>
  <div class="height-100">
    <div v-if="this.$store.state.dashboardStore.documents.length > 0">
      <table class="mdl-data-table mdl-js-data-table mdl-table-custom sm-2 mt-0">
        <tbody>
        <tr v-for="(document, documentIndex) in this.$store.state.dashboardStore.documents"
            :key="'d' + documentIndex"
            :class="{ dragged: dragged.includes('d' + document.id) }"
        >
          <td width="20">
            <span class="badge align-items-center p-2" v-bind:class="[new Date(document.createdAt).getMonth() == new Date().getMonth() ? 'green' : 'primary']">
              {{ new Date(document.createdAt).toLocaleDateString('fr-FR', { day: 'numeric', month: "short" }).toUpperCase() }}
            </span>
          </td>
          <td :data-label="$t('message.name')">
            <i :class='"el-type mdi " + resolveIcon(document)'></i><span class="ml-i1 font-weight-bold">{{ document.name }}.{{ document.extension }}</span>
            <span v-if="document.read === false && hasEditableContent === false" class="unread badge bg-danger">{{ $t('message.new') }}</span>
          </td>
          <td class="actions" :data-label="$t('message.actions')">
            <button :title="$t('message.download')" v-on:click="downloadDocument(document)"><i class="fas fa-download"></i></button>
            <button v-if="isPreviewable(document)" :title="$t('message.view')" v-on:click="openPreviewDocumentDialog(document)"><i class="fas fa-eye"></i></button>
            <router-link v-if="document.folderUrl != null" :title="$t('message.display_file_location')" class="button text-primary" :to="document.folderUrl" @click.native="collapse" draggable="false">
              <i class="mdi mdi-folder-eye mdi-18px"></i>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex flex-column height-100 text-center justify-content-center" v-else>
      <div>
        <div><i class="fas fa-exclamation-triangle text-size-30 mb-5 light-gray"></i></div>
        <h4>
          {{ $t('message.no_file_last_35_days') }}
        </h4>
      </div>
    </div>
    <div id="watermark" v-if="hasEditableContent && documents.length === 0 && loading === false">
      <span class="font-weight-bold">{{ $t('message.drop_files_here') }}</span>
      <br>
      <span>{{ $t('message.or_use_upload_button') }}</span>
      <img src="@/assets/images/watermark.png" draggable="false">
    </div>

    <PreviewPdfDialog
      v-if="documentToPreview && destroyPreviewPdfComponent"
      :key="654687684"
      :preview-pdf-dialog-state="previewPdfDialogState"
      :document="documentToPreview"
      @closePreviewPdfDialog="closePreviewPdfDialog($event)"
    ></PreviewPdfDialog>

    <PreviewImgDialog
      v-if="documentToPreview && destroyPreviewImgComponent"
      :key="12314657895"
      :preview-img-dialog-state="previewImgDialogState"
      :document="documentToPreview"
      @closePreviewImgDialog="closePreviewImgDialog($event)"
    ></PreviewImgDialog>

    <PreviewXlsDialog
      v-if="documentToPreview && destroyPreviewXlsComponent"
      :key="963258415"
      :preview-xls-dialog-state="previewXlsDialogState"
      :document="documentToPreview"
      @closePreviewXlsDialog="closePreviewXlsDialog($event)"
    ></PreviewXlsDialog>

    <PreviewCsvDialog
      v-if="documentToPreview && destroyPreviewCsvComponent"
      :key="8855523647"
      :preview-csv-dialog-state="previewCsvDialogState"
      :document="documentToPreview"
      @closePreviewCsvDialog="closePreviewCsvDialog($event)"
    ></PreviewCsvDialog>

    <PreviewTxtDialog
      v-if="documentToPreview && destroyPreviewTxtComponent"
      :key="99945523"
      :preview-txt-dialog-state="previewTxtDialogState"
      :document="documentToPreview"
      @closePreviewTxtDialog="closePreviewTxtDialog($event)"
    ></PreviewTxtDialog>

  </div>
</template>

<style scoped>
  .height-100 {
    height: 100% !important;
  }
  .light-gray {
    color: lightgrey !important;
  }
</style>

<script>

import Vue from 'vue'
import config from "../../config";
import FileSaver from 'file-saver'
import PreviewPdfDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewPdfDialog'
import PreviewImgDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewImgDialog'
import PreviewXlsDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewXlsDialog'
import PreviewCsvDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewCsvDialog'
import PreviewTxtDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewTxtDialog'

export default {
  name: 'SimpleTable',
  data: () => {
    return {
      hasEditableContent: false,
      dragged: [],
      documents:[],
      documentToPreview: null,
      previewPdfDialogState: false,
      previewImgDialogState: false,
      previewXlsDialogState: false,
      previewCsvDialogState: false,
      previewTxtDialogState: false,
      destroyPreviewPdfComponent: true,
      destroyPreviewImgComponent: true,
      destroyPreviewXlsComponent: true,
      destroyPreviewCsvComponent: true,
      destroyPreviewTxtComponent: true,
    }
  },
  methods: {
    isPreviewable (document) {
      if (this.isPdf(document)) {
        return true
      }

      if (this.isImg(document)) {
        return true
      }

      if (this.isCsv(document)) {
        return true
      }

      if (this.isTxt(document)) {
        return true
      }
    },
    isPdf (document) {
      const pdf = ['application/pdf']

      return pdf.includes(document.mimeType)
    },
    isImg (document) {
      const image = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml', 'image/gif', 'image/bmp', 'image/vnd.microsoft.icon', 'image/tiff', 'image/webp']

      return image.includes(document.mimeType)
    },
    isXls (document) {
      const excel = ['application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

      return excel.includes(document.mimeType)
    },
    isCsv (document) {
      const table = ['text/csv']

      return table.includes(document.mimeType)
    },
    isTxt (document) {
      const txt = ['text/plain']

      return txt.includes(document.mimeType)
    },
    resolveIcon (document) {
      if (this.isPdf(document)) {
        return 'mdi-file-pdf-outline'
      }

      if (this.isImg(document)) {
        return 'mdi-file-image-outline'
      }

      if (this.isXls(document)) {
        return 'mdi-file-excel-outline'
      }

      if (this.isCsv(document)) {
        return 'mdi-file-table-outline'
      }

      if (this.isTxt(document)) {
        return 'mdi-file-document-outline'
      }

      const word = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

      if (word.includes(document.mimeType)) {
        return 'mdi-file-word-outline'
      }

      const powerpoint = ['application/vnd.oasis.opendocument.presentation', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']

      if (powerpoint.includes(document.mimeType)) {
        return 'mdi-file-powerpoint-outline'
      }

      const audio = ['audio/aac', 'audio/midi', 'audio/x-midi', 'audio/mp3', 'audio/oga', 'audio/wav', 'audio/weba']

      if (audio.includes(document.mimeType)) {
        return 'mdi-file-music-outline'
      }

      const video = ['video/x-msvideo', 'video/mpeg', 'video/ogv', 'video/mp2t', 'application/vnd.visio', 'video/webm']

      if (video.includes(document.mimeType)) {
        return 'mdi-file-video-outline'
      }

      const archive = ['application/gzip', 'application/x-freearc', 'application/zip', 'application/rar', 'application/x-bzip', 'application/x-bzip2', 'application/java-archive', 'application/x-tar', 'application/x-7z-compressed']

      if (archive.includes(document.mimeType)) {
        return 'mdi-archive-outline'
      }

      return 'mdi-file-document-outline'
    },
    downloadDocument (document) {
      if (!document) {
        return
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.name + '.' + document.extension
            if (headers['content-name']) {
              filename = decodeURIComponent(escape(headers['content-name']))
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)

            if (document.read !== true) {
              this.putDocument(document)
            }
          }
        })
        .catch(e => {
          console.log(e);
        })
    },
    openPreviewDocumentDialog (document) {
      if (this.isPdf(document)) {
        this.previewPdfDialogState = true
        this.destroyPreviewPdfComponent = true
      } else if (this.isImg(document)) {
        this.previewImgDialogState = true
        this.destroyPreviewImgComponent = true
      } else if (this.isCsv(document)) {
        this.previewCsvDialogState = true
        this.destroyPreviewCsvComponent = true
      } else if (this.isTxt(document)) {
        this.previewTxtDialogState = true
        this.destroyPreviewTxtComponent = true
      }

      this.documentToPreview = document
    },
    putDocument (document) {
      const data = {
        id: document.id,
        read: true,
        vault: this.$store.getters.getCurrentVault.id
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/documents/' + document.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            document.read = true
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400) {
          }

          if (response.status === 500) {
          }
        })
    },
    closePreviewPdfDialog () {
      this.previewPdfDialogState = false
      this.destroyPreviewPdfComponent = false
    },
    closePreviewImgDialog () {
      this.previewImgDialogState = false
      this.destroyPreviewImgComponent = false
    },
    closePreviewXlsDialog () {
      this.previewXlsDialogState = false
      this.destroyPreviewXlsComponent = false
    },
    closePreviewCsvDialog () {
      this.previewCsvDialogState = false
      this.destroyPreviewCsvComponent = false
    },
    closePreviewTxtDialog () {
      this.previewTxtDialogState = false
      this.destroyPreviewTxtComponent = false
    },
  },
  components: {
    PreviewPdfDialog,
    PreviewImgDialog,
    PreviewXlsDialog,
    PreviewCsvDialog,
    PreviewTxtDialog,
  }
}
</script>
