<template>
  <div class="d-block h-100 flex-basis-30">
    <v-card class="pl-3 pr-3 w-100 rounded-xl h-100 d-flex flex-column flex-nowrap justify-space-between align-items-stretch align-content-stretch">
      <v-card-title class="flex-grow-0 text-primary justify-center font-weight-bold pb-0">
        Info
      </v-card-title>

      <v-card-text class="flex-grow-1">
        <div v-if="validateReleaselogs()" class="d-flex flex-column h-100">
          <div class="flex-grow-0 font-weight-bold text-size-16">{{ releaselogs[currentReleaseLog].title }}</div>
          <div class="flex-grow-1 text-justify releaselog-text-limit" v-html="releaselogs[currentReleaseLog].content"></div>
          <div class="flex-grow-0 text-right">
            <a href="javascript:;" v-on:click="openModal(releaselogs[currentReleaseLog])" class="mt-5" > > en voir plus</a>
          </div>
        </div>
        <div v-else>
          <v-card-text class="d-flex align-items-center justify-center align-center">
            <div class="text-center text-size-18"><i class="mdi mdi-information text-gray-500"></i> {{ $t('message.no_information_at_the_moment') }}</div>
          </v-card-text>
        </div>
      </v-card-text>

      <v-card-actions class="flex-grow-0">
        <div class="d-flex w-100 flex-row align-content-between justify-content-between">
          <div>
            <v-btn v-on:click="prevItem" color="primary" elevation="2" rounded :disabled="currentReleaseLog == 0">
              <i class="mdi mdi-chevron-triple-left mdi-18px"></i>
            </v-btn>
          </div>
          <div>
            <p class="pt-2">
              {{ totalReleaseLogs == null || totalReleaseLogs == 0 ? '--' : currentReleaseLog+1 }}
              /
              {{ totalReleaseLogs == null || totalReleaseLogs == 0 ? '--' : totalReleaseLogs }}</p>
          </div>
          <div>
            <v-btn v-on:click="nextItem" color="primary" elevation="2" rounded :disabled="currentReleaseLog+1 == totalReleaseLogs || totalReleaseLogs == null || totalReleaseLogs == 0">
              <i class="mdi mdi-chevron-triple-right mdi-18px"></i>
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
    <PreviewReleaselogDialog :releaselog="releaselog"
                             :previewReleaselogDialogState="previewReleaselogDialogState"
                             @closePreviewReleaseDialog="previewReleaselogDialogState = false">
    </PreviewReleaselogDialog>
  </div>
</template>

<script>

import PreviewReleaselogDialog from "../Dialog/Releaselog/PreviewReleaselogDialog";

export default {
  name: 'CardInfo',
  components: { PreviewReleaselogDialog },
  data: () => {
    return {
      previewReleaselogDialogState: false,
      totalReleaseLogs: null,
      currentReleaseLog: 0,
      releaselog: null,
    }
  },
  computed: {
    releaselogs: {
      get: function () {
        return this.$store.state.dashboardStore.releaselogs;
      },
      set: function (value) {
        this.$store.commit('dashboardStore/updateReleaselogs', value)
      },
    },
  },
  methods: {
    openModal(releaselog) {
      this.releaselog = releaselog;
      this.previewReleaselogDialogState = true;
    },
    closeModal() {
      this.previewReleaselogDialogState = false;
    },
    nextItem() {
      this.currentReleaseLog += 1;
    },
    prevItem() {
      this.currentReleaseLog -= 1;
    },
    validateReleaselogs() {
      return this.releaselogs.length > 0;
    }
  },
  watch: {
    releaselogs() {
      this.totalReleaseLogs = this.releaselogs.length;
    }
  }
}
</script>

<style scoped>

.releaselog-text-limit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 50px;
}

.flex-basis-30 {
  flex-basis: 30%;
}

a:hover {
  color: orangered;
}

</style>
