<template>
  <v-card outlined tile class="w-100 rounded-xl card-date">
    <v-card-title align="center" class="text-primary text-bold justify-center text-white text-size-16">{{ getDateDay() }}</v-card-title>

    <v-card-text class="text-white justify-center text-center">
      <div class="text-size-60">{{ getDateWeekDay() }}</div>
    </v-card-text>

    <v-card-actions class="text-white justify-center">
      <h3 class="text-uppercase">
        {{ getDateMonth() }}
      </h3>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  data: () => ({
    selection: 1,
    date: new Date(),
    monthNames: ["JAN", "FÉV", "MAR", "APR", "MAI", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
  }),
  methods: {
    getDateDay() {
      return this.date.toLocaleDateString('fr-FR', { weekday: 'long'}).toUpperCase();
    },
    getDateMonth() {
      return this.monthNames[this.date.getMonth()];
    },
    getDateWeekDay() {
      return this.date.toLocaleDateString('fr-FR', { day: 'numeric'});
    }
  },
}
</script>
