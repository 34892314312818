<template>
  <div class="main-blue">
    <BackgroundDraw></BackgroundDraw>
    <div id="loading" v-if="this.$store.state.dashboardStore.loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="container dashboard-content" height="100%">
      <div class="row pt-0 mt-0 h-100">
        <div class="col-lg-4">
          <CardStorage></CardStorage>
        </div>
        <div class="col-lg-8 two-cards">
          <CardTable></CardTable>
          <CardInfo></CardInfo>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.two-cards {
  display: flex;
  flex-direction: column;
}

</style>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue';
import BackgroundDraw from "../layouts/BackgroundDraw";
import WelcomeDashboard from "../components/Dashboard/WelcomeDashboard";
import CardStorage from "../components/Dashboard/CardStorage";
import CardTable from "../components/Dashboard/CardTable";
import CardInfo from "../components/Dashboard/CardInfo";
import CardDate from "../components/Dashboard/CardDate";

export default {
  name: 'Home',
  data: () => {
    return {}
  },
  created () {
    this.$emit('update:layout', DefaultLayout)

    const today = new Date();
    let tmp = new Date();
    tmp.setDate(today.getDate()-35);
    let less35Days = new Date(tmp);

    let params = {
      from: less35Days.getFullYear() + '-' + ('0' + (less35Days.getMonth()+1)).slice(-2) + '-' + less35Days.getDate().toString().padStart(2, "0"),
      to: today.getFullYear() + '-' + ('0' + (today.getMonth()+1)).slice(-2) + '-' + today.getDate().toString().padStart(2, "0")
    }

    this.$store.dispatch('dashboardStore/getDocuments', params);
    this.$store.commit('dashboardStore/updateUser', this.$store.state.user);
    this.$store.dispatch('dashboardStore/getReleaselogs');
    this.$store.dispatch('storageStore/getPersonalStorageStatus');
  },
  computed: {},
  mounted () {},
  components: {
    WelcomeDashboard,
    BackgroundDraw,
    CardStorage,
    CardTable,
    CardDate,
    CardInfo
  },
  methods: {
    reserve () {
      this.loading = true

      setTimeout(() => (this.loading = false), 3000)
    },
  }
}
</script>
